import React, { useState, useReducer, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Button, TextField, MenuItem } from '@mui/material';

import institutionService from '../../services/institution';
import AppContext from '../../context/app'

export default function addBuilding(props) {
    const context = useContext(AppContext)
    const { buildingId } = useParams()

    const [institutions, setInstitutions] = useState([])
    const [institutionId, setInstitutionId] = useState()
    let [buildingToAdd, setBuildingToAdd] =  useReducer((state, newState) => ({ ...state, ...newState }),{});

    useEffect( async () => {
        try {
            //edit mode
            if (buildingId) {
                let loadedBuilding = await new institutionService().getBuilding(buildingId)
                setInstitutionId(loadedBuilding.institutionId);
                setBuildingToAdd(loadedBuilding)
            }
            //add mode
            else {
                let loadedInstitutions = await new institutionService().getInstitutions()
                if (loadedInstitutions.length === 1 )
                    setInstitutionId(loadedInstitutions[0].institutionId);
                setInstitutions(loadedInstitutions);
            }
        }
        catch(err){
            console.log(err)
            context.toastError('Loading')
            Sentry.captureException(err);
        }
    },[])

    useEffect( async () => {
       buildingToAdd.institutionId = institutionId
       setBuildingToAdd(buildingToAdd)
    },[institutionId])

    let handleAddBuilding = async (event) => {
        event.preventDefault();
        try {
            await new institutionService().createBuilding(buildingToAdd)
            context.toastSuccess('Building Saved')
            props.history.push({pathname: '/users'});
        }
        catch (err){
            console.log(err)
            context.toastError('Error creating building.')
            Sentry.captureException(err);
        }
    }

    let handleEditBuilding = async (event) => {
        event.preventDefault();
        try {
            await new institutionService().updateBuilding(buildingToAdd)
            context.toastSuccess('Building Saved')
            props.history.push({pathname: '/users'});
        }
        catch (err){
            console.log(err)
            context.toastError('Error updating building.')
            Sentry.captureException(err);
        }
    }


    return (
        <div id='building-add-edit'>
            <form onSubmit={buildingId ? handleEditBuilding : handleAddBuilding}>
                <Paper className="paper">
                { !buildingId && institutions.length > 1 ? 
                    <div className="section">
                        <TextField fullWidth select label="Institution" value={buildingToAdd.institutionId || ''} onChange={(e) => { setInstitutionId(+e.target.value)}}>
                        { institutions.map( (institution, index) => {
                            return(
                                <MenuItem key={index} value={institution.institutionId}>{institution.name}</MenuItem>
                            )
                        }) 
                        }
                        </TextField>
                    </div>
                : null }


                <div className="section">
                    <TextField fullWidth required value={buildingToAdd.buildingName || ''} maxLength="45" type="text" label="Building Name" onChange={(e) => { buildingToAdd.buildingName = e.target.value; setBuildingToAdd(buildingToAdd)}}/>
                </div>

                <div className="section">
                    <TextField fullWidth required value={buildingToAdd.buildingAddress || ''} maxLength="45" type="text" label="Building Address" onChange={(e) => { buildingToAdd.buildingAddress = e.target.value; setBuildingToAdd(buildingToAdd)}}/>
                </div>

                <div className="section">
                    <TextField fullWidth required value={buildingToAdd.buildingPhone || ''} maxLength="45" type="text" label="Building Phone" onChange={(e) => { buildingToAdd.buildingPhone = e.target.value; setBuildingToAdd(buildingToAdd)}}/>
                </div>
                </Paper>

                <div className="invite-actions">
                    <Button variant="outlined" type="submit">
                        <Icon className="fa fa-save" />Save
                    </Button>

                    <Link className='back' to="/users">
                        <Button variant="outlined" type="submit">
                            <Icon className="fa fa-arrow-left" />Back
                        </Button>
                    </Link>
                </div>
            </form>
            </div>
    )
}