import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import AppContext from '../context/app'
import institutionService from '../services/institution';


class Sidenav extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { };
    this.sideBarClick = this.sideBarClick.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    this.institutionService = new institutionService()
  }

  nextStep(e) {
    e.preventDefault()
    window.scroll(0,0)
  }
  
  sideBarClick(e) {
    this.setState( { showNav: false } )
  }

  toggleNav(e){
    this.setState( { showNav: !this.state.showNav } )
  }

  async componentDidMount () {
 
  }

  render () {
    let inviteLinkSelectedClass = 'module flex-row'
    let adminSelectedClass = 'module flex-row'
    let usersLinkSelectedClass = 'module flex-row'
    if(this.props.history.location) {
      if (this.props.history.location.pathname === '/' || this.props.history.location.pathname.indexOf('invite') > -1 )
        inviteLinkSelectedClass = 'module active flex-row'
      
      if (this.props.history.location.pathname.indexOf('admin') > -1 )
        adminSelectedClass = 'module active flex-row'

      if (this.props.history.location.pathname.indexOf('users') > -1 )
          usersLinkSelectedClass = 'module active flex-row'
    }

    return (
      <section id="sidebar">
        <section className="desktop">
              <div className="managerView-link"  onClick={this.sideBarClick}>
                <Link className={usersLinkSelectedClass} to="/users">
                <div className="linkStyle">
                  Users
                </div>
                </Link>
              </div>  

              <div className="managerView-link"  onClick={this.sideBarClick}>
                  <Link className={adminSelectedClass} to="/admin/institutions">
                  <div className="linkStyle">
                    Admin Report
                  </div>
                  </Link>
              </div>
        </section>

        <section className="tablet">
          <div>
            { !this.state.showNav ?
              <i className="fa fa-bars showNav-bar" onClick={this.toggleNav}></i>
            :
              <i className="fa fa-minus showNav-bar" onClick={this.toggleNav}></i>
            }
          </div>
        
          { this.state.showNav ?
            <div className="mobile-nav">
              <div className="managerView-link"  onClick={this.sideBarClick}>
              <Link className={usersLinkSelectedClass} to="/users">
                <div className="linkStyle">
                  Users
                </div>
                </Link>
              </div>

              <div className="managerView-link"  onClick={this.sideBarClick}>
                <Link className={adminSelectedClass} to="/admin/institutions">
                <div className="linkStyle">
                  Admin Report
                </div>
                </Link>
              </div>
            </div>
          : null }
        </section>
      </section>
    )
  }
}

export default withRouter(Sidenav)
