import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../context/app'
import * as Sentry from '@sentry/browser';

import CircularProgress from '../components/CircularProgressWithLabel';

import DotMenu from '../components/Menu';
import DotMenuItem from '../components/MenuItem';

import userService from '../services/user';
import Tooltip from '@mui/material/Tooltip';
import HelpTooltip from './HelpToolTip';

export default function UserList(props) {
  const context = useContext(AppContext)
  const [hasCompletableSystems, setHasCompletableSystems] = useState(false)

  useEffect(() => {
    let systems = context.systems
    setHasCompletableSystems(systems.some( s => s.isCompletable ))
  },[])

  let handleReInvite = async (user) => {
    try {
      await new userService().reInvite(user.email)
      context.toastSuccess('User Re-Invited')
    }
    catch (err){
      console.log(err)
      context.toastError(`Invite failed ${user.email}`)
      Sentry.captureException(err);
    }
  }

  //Remove the user from a building
  let handleDelete= async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await new userService().delete(userId, props.building.institutionBuildingId)
        let users = props.building.users
        //set the user as remove
        let deletedIndex = users.findIndex( (allUsersuser) => { return allUsersuser.userId === userId } );
        if (deletedIndex >= 0)
        users.splice(deletedIndex, 1)
        props.onUpdateBuildingUsers(users)
        context.toastSuccess('User removed')
      }
      catch (err){
        console.log(err)
        context.toastError(`Remove failed`)
        Sentry.captureException(err);
      }
    }
  }

  let downloadUserCertificate = (userId) => {
    new userService().downloadCertificate(userId)
  }

  const behaviorAccessConfig = {
    viewAllThreats: { icon: 'fa-check', helpText: "All Behavior Assessments"},
    threatTeam: { text: 'T', helpText: "Threat Team"},
    viewSelfHarm: { text: 'S', helpText: "Self Harm Assessments"},
    reviewThreats: { text: 'R', helpText: "Review HPTM Behavior Assessments"}
  }
  let behaviorAssessmentAccess = (user) => {
    let behaviorAccess = []
    if (user.viewAllThreats)
      behaviorAccess.push('viewAllThreats')
    else {
      if (user.threatTeam)
        behaviorAccess.push('threatTeam')
      if (user.viewSelfHarm)
        behaviorAccess.push('viewSelfHarm')
    }
    if (user.reviewThreats)
      behaviorAccess.push('reviewThreats')


    return(
      <div>
        { 
          behaviorAccess.map( (ba, index) => {
            return (
              <span key={index} className="multipleAccess">
                <Tooltip
                      placement="top"
                      title={behaviorAccessConfig[ba].helpText}
                  > 
                  { behaviorAccessConfig[ba].icon ?
                    <i className={`fa ${behaviorAccessConfig[ba].icon}`}/>
                  :       
                  <span>{behaviorAccessConfig[ba].text}</span>
                  }
                </Tooltip>
              </span>
            )
          })
        }
      </div>
    )

  }

  if (!props.building.users.length)
    return ( 
      <div></div> 
    )

  return (  
    <div>
      <header className="header">
        <div className="col">
          Email
        </div>
        <div className="col hide-tablet">
          Name
        </div>
        { hasCompletableSystems ? 
          <div className="col hide-phone">
            Training 
            <div className="header-extra aggregateTrainingPercent hide-tablet"><CircularProgress className={props.aggregateTrainingPercent === 100 ? 'complete' : ''} value={props.aggregateTrainingPercent}></CircularProgress></div>
          </div>
        : null }
        <div className="col hide-tablet">
          User Management
        </div>
        <div className="col hide-tablet">
          Behavioral Assessments
        </div>
        <div className="col hide-tablet">
          Emergency Plan
        </div>
        <div className="col hide-tablet">
          Vulnerability Assessment
        </div>
        <div className="col">
          Action
        </div>
      </header>
      {
        props.building.users.map( (user, index) => {
          let percentClass = user.trainingCompletePercentage === 100 ? 'complete' : ''
          return (
            <div key={index} className="rows row" >
              <div className="col">
                {user.email}
              </div>
              <div className="info col hide-tablet">
                <span className="user-name">
                    { user.firstName && user.lastName ?
                      <span>
                        <span dangerouslySetInnerHTML={ { __html: user.firstName} }></span>&nbsp; 
                        <span dangerouslySetInnerHTML={ { __html: user.lastName} }></span> 
                      </span>
                    :
                      <span>(Not Entered)</span>
                    }
                </span>
              </div>

              { hasCompletableSystems ? 
              <div className="col userPercent hide-phone">
                { user.training ?
                <CircularProgress className={percentClass} value={user.trainingCompletePercentage > 1 ? user.trainingCompletePercentage : 0}></CircularProgress>
                : null }
              </div>
              : null }

              <div className="col hide-tablet">
               { user.userManagement ? <i className="fa fa-check"/> : null }
              </div>
              
              <div className="col hide-tablet">
                {behaviorAssessmentAccess(user)}
              </div>

              <div className="col hide-tablet">
                { user.emergencyPlan ? <i className="fa fa-check"/> : null }
              </div>
              <div className="col hide-tablet">
                { user.riskSurvey ? <i className="fa fa-check"/> : null }
              </div>

              
              <div className="col menu-holder">
                <DotMenu>
                  <DotMenuItem onClick={ e => handleReInvite(user)} fontAwesomeIcon="fa fa-paper-plane" title="Re-Invite" ></DotMenuItem>
                  { user.trainingCompletePercentage === 100 ?
                    <DotMenuItem onClick={ e => downloadUserCertificate(user.userId)} fontAwesomeIcon="fa fa-certificate" title="Certificate" ></DotMenuItem>
                  : null }
                  <DotMenuItem onClick={ e =>  { let editedUser = props.building.users.find( u => u.userId === user.userId); props.onSetEditedUser({...editedUser}) }} fontAwesomeIcon="fa fa-edit" title="Edit" ></DotMenuItem>
                  <DotMenuItem onClick={ e => handleDelete(user.userId)} fontAwesomeIcon="fa fa-user-minus" title="Remove" ></DotMenuItem>
                </DotMenu> 
              </div>
            </div>   
          );
        })
      }
    </div>
  )
}