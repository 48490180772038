import {get} from './http'

export default class institutionService {
  getInstitutions() {
    return fetch('/api/institution/getInstitutions', { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getBuilding(buildingId) {
    return fetch(`/api/institution/getBuilding?buildingId=${buildingId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }
  
  createBuilding(building) {
    return fetch('/api/institution/createBuilding', { 
      method: 'POST', 
      body: JSON.stringify(building), 
      mode: 'cors',
      credentials: 'include', 
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  updateBuilding(building) {
    return fetch('/api/institution/updateBuilding', { 
      method: 'POST', 
      body: JSON.stringify(building), 
      mode: 'cors',
      credentials: 'include', 
      headers: {'Accept': 'application/json','Content-Type': 'application/json'}
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getAdminInstitutionSystems(){
    return get('/api/institution/getAdminInstitutionSystems', { })    
  }

  getAdmin(system) {
    return get('/api/institution/getAdmin', { })    
  }

  downloadAdminReport() {
    window.location = '/api/institution/downloadAdminReport'
  }

  downloadUsersReport() {
    window.location = '/api/institution/downloadUsersReport'
  }
  

}