import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import AppContext from '../context/app'
import * as Sentry from '@sentry/browser';

import { Paper, Icon, Button, Checkbox, FormGroup, FormControlLabel, FormControl, TextField, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {DropzoneAreaBase} from 'material-ui-dropzone'

import HelpToolTip from './HelpToolTip';

import userService from '../services/user';

import typicalRoles from '../config/typicalRoles.json'

BulkAddUsers.propTypes = {
  institution: PropTypes.number,
  selectedBuildingId: PropTypes.number,
  buildings: PropTypes.array,
  onSave:  PropTypes.func.isRequired,
  onClose:  PropTypes.func.isRequired
};

export default function BulkAddUsers(props) {
  const context = useContext(AppContext)

  let [uploadType, setUploadType] = useState()
  let [resetMode, setResetMode] = useState(false)
  let [inviteList, setInviteList] = useState()
  let [emailListPermissions, setEmailListPermissions] = useState({})
  let [files, setFiles] = useState([])
  let [inValidEmails, setInvalidEmails] = useState([])
  let [selectedBuildingIds, setSelectedBuildingIds] = useState([])

  useEffect(() => {
    setSelectedBuildingIds([props.selectedBuildingId])
  },[props.selectedBuildingId])

  let handleToggleBuildingId = (buildingId, checked) => {
    let updateSelectedBuildingIds = selectedBuildingIds
    if (checked)
      updateSelectedBuildingIds.push(buildingId)
    else
      updateSelectedBuildingIds.splice(updateSelectedBuildingIds.indexOf(buildingId),1)
    setSelectedBuildingIds([...updateSelectedBuildingIds])
  }

  const handleUploadFile = async (files) => {
    if (!files.length)
      return

    setInvalidEmails([])
    try {
      async function uploadFiles() {
        for (const file of files) {
          let data = new FormData()
          data.append('file', file.file)
          let inviteResult = await new userService().inviteExcel(props.institution?.institutionId, selectedBuildingIds, data, +resetMode)
          if (inviteResult.error){
            setInvalidEmails(inviteResult.emails)
            window.scroll(0,0)
          }
        }
      }

      await uploadFiles.bind(this)()

      //bind them and reset for next image
      props.onSave()
      setFiles([])
      context.toastSuccess('Invites sent')
    }
    catch(err){
      console.log(err)
      Sentry.captureException(err);
      context.toastError('Invites could not send')
      setFiles([])
    }

  }

    //send invites to list entered
  let handleInvite = async () => {
    try {
      let invites = inviteList
      invites = invites.trim()
      
      //split on comma, spaces, semicolons
      console.log('inviteList',invites)
      if (!invites.length)
        return

      //replace char returns
      invites = invites.replace('/n','')
      invites = invites.trim().split(/[,; ]+/);

      invites = invites.map( (invite) => { 
        let inviteObject = {};
        //for outlook copy paste
        if (invite.indexOf('<') > -1){
          //split on <
          let inviteSplit = invite.split('<');
          inviteSplit[0] = inviteSplit[0].trim();
          inviteSplit[1] = inviteSplit[1].replace('>','').trim();

          //get the firstName lastName
          let inviteNameSplit = inviteSplit[0].split(' ');
          inviteObject.firstName = inviteNameSplit[0];
          inviteObject.lastName = inviteNameSplit[1];

          //get the email
          inviteObject.email = inviteSplit[1].trim();
        } 
        else
          inviteObject.email = invite.trim();
        
        return inviteObject;
      })
      .filter( (invite) => { return invite.email.length } )

      //unique
      invites = Array.from([...new Set(invites.map(i => i.email))]).map( email => { return invites.find( ii => ii.email === email ) })
      
      //test emails
      let emailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let inValidEmails = [];
      invites.forEach( (invite) => {
        if (!emailValidator.test(invite.email))
          inValidEmails.push(invite.email);      
      })

      if (!inValidEmails.length) {
        await new userService().inviteList(invites,props.institution?.institutionId, selectedBuildingIds , emailListPermissions.training, emailListPermissions.userManagement, emailListPermissions.threatTeam, emailListPermissions.viewSelfHarm, emailListPermissions.viewAllThreats, emailListPermissions.reviewThreats, emailListPermissions.emergencyPlan, emailListPermissions.riskSurvey +resetMode)
        setInviteList()
        props.onSave()
        context.toastSuccess('Users have been sent an invitation email')
      }
      else {
        setInvalidEmails(inValidEmails);
        window.scroll(0,0)
      }

    }
    catch (err) {
      console.log(err)
      context.toastError('Invites could not send')
      Sentry.captureException(err);
    }
  }

  let buildingOptions = () => {
    return (
      <div>
        { !!selectedBuildingIds.length > 1 || props.buildings.length > 1 ?
          <Paper className="paper">
              <h2>Buildings</h2>
              <div className="flex-row section" >
                  <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                      { props.buildings.map( (building, index) => {
                          return(
                          <FormControlLabel key={index}
                              control={<Checkbox required={requiredSelectedBuildingIds} />}
                              label={building.buildingName}
                              labelPlacement="end"
                              checked={!!selectedBuildingIds.find(b => b === building.institutionBuildingId)}
                              onChange={ (e)=> handleToggleBuildingId(building.institutionBuildingId, e.target.checked) }
                          />
                          )
                      })}
                  </FormGroup>
                  </FormControl>
              </div>
          </Paper>
        : null }
      </div>
    )
  }

  let uploadSettings = () => {
    return (
      <div>
    <FormControl 
        component="fieldset"
    >
      <h2><span>Access</span><span><HelpToolTip title={<div><div>Training - Require User to take training</div><div>User Management - Access to Add, Edit and View Users</div><div>Threat Team - Members handling Behavior Assessments</div><div>View All Behavioral Assessments - Access to edit all Behavior Assessments</div><div>Emergency Management - Access to edit and view Plans</div><div>Vulnerability Assessment - Access to edit and view Vulnerability Assessments</div></div>}></HelpToolTip></span></h2>

      <div>
        <TextField className="typical-role" select label="Typical Roles" onChange={ e => { let updatePermissions = typicalRoles.find( tr => tr.name === e.target.value).permissions; emailListPermissions = Object.assign(emailListPermissions, updatePermissions); setEmailListPermissions({...emailListPermissions}) } }>
              { typicalRoles.map( (typicalRole, index) => {
                  return(
                      <MenuItem key={index} value={typicalRole.name}>{typicalRole.name}</MenuItem> 
                  )
                  })
              }                                
        </TextField>
      </div>

        <FormGroup aria-label="position">
            <FormControlLabel
                control={<Checkbox required={requiredCheckbox} />}
                label="Training"
                labelPlacement="end"
                checked={!!emailListPermissions.training}
                onChange={ (e) => { emailListPermissions.training = e.target.checked ? 1 : 0 ; setEmailListPermissions({...emailListPermissions}) }}
            />
            <FormControlLabel
                control={<Checkbox required={requiredCheckbox} />}
                label="User Management"
                labelPlacement="end"
                checked={!!emailListPermissions.userManagement}
                onChange={ (e) => { emailListPermissions.userManagement = e.target.checked ? 1 : 0 ; setEmailListPermissions({...emailListPermissions}) }}
            />
            <FormGroup aria-label="position" row>
                <FormControlLabel
                    control={<Checkbox required={requiredCheckbox} />}
                    label="View All Behavioral Assessments"
                    labelPlacement="end"
                    checked={!!emailListPermissions.viewAllThreats}
                    onChange={ (e) => { emailListPermissions.viewAllThreats = e.target.checked ? 1 : 0 ; setEmailListPermissions({...emailListPermissions}) }}
                />

                <FormControlLabel
                    control={<Checkbox required={requiredCheckbox} />}
                    disabled={!!emailListPermissions.viewAllThreats}
                    label="Threat Team"
                    labelPlacement="end"
                    checked={!!emailListPermissions.threatTeam}
                    onChange={ (e) => { emailListPermissions.threatTeam = e.target.checked ? 1 : 0 ; setEmailListPermissions({...emailListPermissions}) }}
                />
                <FormControlLabel
                    control={<Checkbox required={requiredCheckbox} />}
                    disabled={!!emailListPermissions.viewAllThreats}
                    label="View Self Harm"
                    labelPlacement="end"
                    checked={!!emailListPermissions.viewSelfHarm}
                    onChange={ (e) => { emailListPermissions.viewSelfHarm = e.target.checked ? 1 : 0 ; setEmailListPermissions({...emailListPermissions}) }}
                />

            </FormGroup>
            <FormControlLabel
                control={<Checkbox required={requiredCheckbox} />}
                label=" Emergency Plan"
                labelPlacement="end"
                checked={!!emailListPermissions.emergencyPlan}
                onChange={ (e) => { emailListPermissions.emergencyPlan = e.target.checked ? 1 : 0 ; setEmailListPermissions({...emailListPermissions}) }}
            />
            <FormControlLabel
                control={<Checkbox required={requiredCheckbox} />}
                label=" Vulnerability Assessment"
                labelPlacement="end"
                checked={!!emailListPermissions.riskSurvey}
                onChange={ (e) => { emailListPermissions.riskSurvey = e.target.checked ? 1 : 0 ; setEmailListPermissions({...emailListPermissions}) }}
            />
        </FormGroup>
    </FormControl>

    <div className="section">
    <h2><span>Additional Permissions</span><span><HelpToolTip title={<div><div>Review HPTM Behaviors - Review All HPTM Threats created at this building</div></div>}></HelpToolTip></span></h2>

    <FormControlLabel
        control={<Checkbox required={requiredCheckbox} />}
        label="Review HPTM Behaviors"
        labelPlacement="end"
        checked={!!emailListPermissions.reviewThreats}
        onChange={ (e) => { emailListPermissions.reviewThreats = e.target.checked ? 1 : 0 ; setEmailListPermissions({...emailListPermissions}) }}
    />
    </div>
    </div>
    )
  }

  const requiredCheckbox = !emailListPermissions.training && !emailListPermissions.userManagement && !emailListPermissions.threatTeam && !emailListPermissions.viewSelfHarm && !emailListPermissions.viewAllThreats && !emailListPermissions.reviewThreats && !emailListPermissions.emergencyPlan && !emailListPermissions.riskSurvey 
  const requiredSelectedBuildingIds= !selectedBuildingIds.length

  return (
    <div className='bulkAddUsers'>
        <div id='manager-invite'>
          <h2>Invite Multiple Users to { props.institution?.name || ''}</h2>
          { !uploadType ? 
          <div>
            <div>How would you like to invite users:</div>
            <div className="flex-row invite-choice section">
              <div className="flex-col section">
                <Box sx={{ maxWidth: 275 }}>
                  <Card variant="outlined">
                    <React.Fragment>
                      <CardContent className="card-content">
                        <i className="fa fa-table"></i> Upload A File
                      </CardContent>
                      <CardActions>
                      <Button onClick={e => setUploadType('file') } variant="outlined"><Icon className="fa fa-arrow-right" />Upload</Button>
                      </CardActions>
                    </React.Fragment>
                  </Card>
                </Box>
              </div>

              <div className="flex-col section">
                <Box sx={{ maxWidth: 275 }}>
                  <Card variant="outlined">
                    <React.Fragment>
                      <CardContent className="card-content">
                        <i className="fa fa-copy"></i> Copy An Email List
                      </CardContent>
                      <CardActions>
                        <Button onClick={e => setUploadType('paste') } variant="outlined"><Icon className="fa fa-arrow-right" />Copy</Button>
                      </CardActions>
                    </React.Fragment>
                  </Card>
                </Box>
              </div>
            </div>
          </div>
          : null }


          { uploadType === 'file' ?
            <div>
              <div className="flex-row">            
                  <div className="flex-col">
                    <FormGroup>
                      <FormControlLabel className="subtle-option" control={ 
                        <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                          checked={resetMode}
                          onChange={ e => { 
                            if (!e.target.checked || (e.target.checked && window.confirm('WARNING: All users that are not listed in the uploaded file will be deleted. The uploaded file must contain the final set of all users for your organization.'))) 
                              setResetMode(e.target.checked)
                          }}
                          value={resetMode} 
                        />
                      } label="Delete Mode" />
                    </FormGroup>
                  </div>
                  <div className="flex-col">
                      <HelpToolTip
                          title={
                              <div>
                                  <b>WARNING:</b> All users that are not listed in the uploaded file will be deleted. The uploaded file must contain the final set of all users for your organization.
                              </div>
                          }>
                      </HelpToolTip>
                  </div>   
              </div>
              <div className="instructions">Upload an Excel file following the format in the template <a download href="/inviteUser.xlsx">Download Template</a>.</div>
              <form className="file-upload-form">
                  <div className="flex-row dropzonearea">                  
                      <DropzoneAreaBase
                        filesLimit={1}
                        maxFileSize={30000000}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onAdd={(fileObjs) => { let newFiles = files.concat(fileObjs); setFiles(newFiles);  handleUploadFile(newFiles)}}
                        fileObjects={files}
                      />
                  </div>
              </form>

              {buildingOptions()}

              <div className="flex-row addUserActions section">
                <div className="flex-col">
                  <Button onClick={e => props.onClose() } variant="outlined"><Icon className="fa fa-close" />Close</Button>
                </div>
              </div>
           </div>


          : null }

          { uploadType === 'paste' ?
          <div>
            <form onSubmit={handleInvite}>
              <div>
                { 
                  inValidEmails && inValidEmails.length ?
                    <div className="errorMessage">
                      Please correct these emails and invite: 
                      <ul>
                        {
                          inValidEmails.map( (invalidEmail, index) => {
                          return (
                              <li key={index}>{invalidEmail}</li>
                          );
                        })
                      }
                      </ul>
                    </div>
                  :
                    null
                }
              </div>
              <div className="instructions">Paste your users email separated by space, comma or semicolon.</div>
                  <div>
                      <TextareaAutosize required name='inviteList' className="input-field" minRows={5} placeholder="Paste Invite List Here" value={inviteList} onChange={ e => { setInviteList(e.target.value)}} />
                  </div>

              {buildingOptions()}
              {uploadSettings()}
        

              <div className="flex-row addUserActions section">
                <div className="flex-col">
                  <Button variant="outlined" type="submit"><Icon className="fa fa-envelope" />Invite</Button>
                </div>
                <div className="flex-col">
                    <Button onClick={e => props.onClose() } variant="outlined"><Icon className="fa fa-close" />Close</Button>
                </div>
              </div>
            </form>
        </div>
        : null }
      </div>
    </div>
  )
}