import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import moment from 'moment'

import { Paper, Icon, Button } from '@mui/material';
import CircularProgress from '../../components/CircularProgressWithLabel';
import Tooltip from '@mui/material/Tooltip';

import institutionService from '../../services/institution';
import trainingService from '../../services/training';
import AppContext from '../../context/app'

export default function AdminInstitutions() {
  const context = useContext(AppContext)

  const [loading, setLoading] = useState(true)
  const [institutions, setInstitutions] = useState([])

  let downloadTraining = async (event) => {
    try {
      new trainingService().downloadTraining()
    }
    catch (err){
      console.log(err)
      this.context.toastError('Download failed')
      Sentry.captureException(err);
    }
  }

  let downloadAdminReport = async (event) => {
    try {
      new institutionService().downloadAdminReport()
    }
    catch (err){
      console.log(err)
      this.context.toastError('Download failed')
      Sentry.captureException(err);
    }
  }

  let downloadUsersReport = async (event) => {
    try {
      new institutionService().downloadUsersReport()
    }
    catch (err){
      console.log(err)
      this.context.toastError('Download failed')
      Sentry.captureException(err);
    }
  }

  useEffect( async () => {
    try {
      let loadedInstitutions = await new institutionService().getAdmin()
      setInstitutions(loadedInstitutions)
    }
    catch(err){
        console.log(err)
        context.toastError('Loading')
        Sentry.captureException(err);
    }
  },[])

  return (
    <div id='admin'>
      <section className="content-area section">
      <div className="inside-content-area">
          <div className="admin-roundcorner-mainframe">
          <div>
            <span className="print" onClick={downloadTraining}>
              <i className="fa fa-print"></i> Print Training
            </span>
            <span className="print" onClick={downloadAdminReport}>
              <i className="fa fa-download"></i> Admin Report
            </span>
            <span className="print" onClick={downloadUsersReport}>
              <i className="fa fa-download"></i> User Report
            </span>
          </div>

          <div className="content-area">
            {
              institutions.map( (institution) => {
                let percentComplete = institution.trainingPercent
                let percentClass = percentComplete === 100 ? 'complete' : ''

                return (
                    <Link key={institution.institutionId} to={`/users/${institution.institutionId}`}>
                      <Paper className="flex-row rows institution">
                        <div className="flex-col institutionName">{institution.name}</div>                   
                        <div className="flex-col institutionTotal progressNumbers hide-phone">{institution.totalUsers} Users</div>
                        <div className="flex-col institutionComplete progressNumbers"><span><CircularProgress className={percentClass} value={institution.totalUsers > 1 ? percentComplete : 0}></CircularProgress></span></div>
                        <div className="flex-col hide-phone"><span className="data-number"><span >{institution.assessmentCount}</span><i className="fa fa-triangle-exclamation" title="Assessments"></i></span> </div>
                        <div className="flex-col hide-phone">{institution.lastDrillDate ? <span className="data-number"><span>{moment(institution.lastDrillDate).format('MM/DD/YYYY')}</span><i className="fa fa-bell" title="Last Drill"></i></span> : null }</div>
                        <div className="flex-col policyAcknowledged">{institution.hasAcknowldgedPolicies ? <span><i className="fa fa-list-check" title="Policies"></i></span> : null }</div>
                        <div className="flex-col hide-phone">{institution.lastSiteVisitDate ? 
                            <Tooltip
                              placement="top"
                              title={
                                <div>
                                    Site Visit: { moment(institution.lastSiteVisitDate).format('MM/DD/YYYY')}
                                    { institution.siteVisitByFirstName ? <div>{ institution.siteVisitByFirstName} {institution.siteVisitByLastName}</div> : null }
                                </div>
                              }
                            >
                              <span className="data-number">
                                { institution.lastSiteVisitActionCompleted ? <span className="complete-check-container" title="Action Plan Entered"><i className='fa fa-check-circle'></i></span> : null }
                                <i className="fa fa-newspaper"></i>
                              </span>
                            </Tooltip>
                          : null }
                        </div>
                    </Paper>
                   </Link>
                );
              })
            }
          </div>
        </div>
      </div>
      </section>
    </div>
  )
}


