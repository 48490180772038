
import React from 'react'

import '../less/MenuItem.less'

class MenuItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = { }
  }


  render () {
    return (
      <div onClick={this.props.onClick} className="menu-item">
          <i className={this.props.fontAwesomeIcon}></i> {this.props.title}
      </div>
    )
  }
}


export default MenuItem;
