import React from 'react';
import { Switch, Route, Redirect, BrowserRouter as Router } from "react-router-dom";

import { createBrowserHistory } from 'history';

import userService from '../services/user';
import institutionService from '../services/institution';

import Users from './Users';
import AdminInstitutions from './Admin/Institutions';
import addEditBuilding from './Building/AddEdit';

import AppError from '../components/AppError'
import Message from '../components/Message'
import Sidenav from '../components/Sidenav';
import Header from '../components/Header';

import { withCookies } from 'react-cookie';
import ga from 'react-ga';
import AppContext from '../context/app'

import '../css/app.css'

const history = createBrowserHistory();

class App extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { loading: true, threatTool: false }
  }

  async componentDidMount () {
    // //Go get who is logged in when we need to
    // const location = history.location

    // Listen for changes to the current location 
    history.listen(location => {
      //act like you changed pages by going to top
      window.scroll(0,0)

      //Log in Analytics
      ga.set({ page: location.pathname });
      ga.pageview(location.pathname);
      
      //security
      if (!this.props.cookies.get('clps'))
        window.location = 'https://login.clpsconsultants.com/'
    })

    //Default page
    if (!this.props.cookies.get('clps'))
      window.location = 'https://login.clpsconsultants.com/'
    else {
      //load up data and goto default page
      try {
        let [user, systems] = await Promise.all([
          new userService().get(),
          new institutionService().getAdminInstitutionSystems()
        ])
        if (!user){
          window.location = 'https://login.clpsconsultants.com/logout'
          return
        }

        console.log('logged in as: ', user.userId)
        ga.set({ user: user.userId });

        this.context.updateUser(user)   
        this.context.updateSystems(systems)   
        
        this.setState({loading: false})
      }
      catch(err) {        
        console.log(err);
        if (!process.env.REACT_APP_NO_RESET_ON_ERROR)
          window.location = 'https://login.clpsconsultants.com/'
      }
    }
}


  render () {
    return (
      <Router>
        { !this.state.loading ? 
        <div>
          <Message></Message>
          <Header/>    
          <div className="app">
            <section className="sidebar-holder">
              <Sidenav/>
            </section>
            <section className="content sidebar-content">
              <AppError>
                <Switch>
                  <Route path="/users/:selectedInstitutionIdParam?" component={Users}/>
                  <Route path="/admin/institutions" component={AdminInstitutions}/>
                  <Route path="/building/add" component={addEditBuilding}/>
                  <Route path="/building/edit/:buildingId" component={addEditBuilding}/>
                  <Route path="/" component={Users}/>
                  <Redirect path="/" to="/users" />
                </Switch>
              </AppError>
            </section>
          </div>
        </div>
        : null }
      </Router>
    )
  }
}

export default withCookies(App)