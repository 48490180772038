import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import AppContext from '../context/app'
import * as Sentry from '@sentry/browser';

import { Paper, Icon, Button, TextField, MenuItem, FormGroup, FormControl, FormControlLabel, Checkbox } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useDebouncedCallback } from 'use-debounce';
import _ from 'lodash'

import AddUser from '../components/AddUser';
import BulkAddUsers from '../components/BulkAddUsers';
import UserList from '../components/UserList';

import DotMenu from '../components/Menu';
import DotMenuItem from '../components/MenuItem';
import HelpTooltip from '../components/HelpToolTip';

import userService from '../services/user';
import institutionService from '../services/institution';

const progressOptions = [
  { status: "Incomplete", filter: (u) => u.trainingCompletePercentage < 100  },
  { status: "Not Started", filter: (u) => u.trainingCompletePercentage === 0 },
  { status: "In-Progress", filter: (u) => u.trainingCompletePercentage > 0 && u.trainingCompletePercentage < 100 },
  { status: "Complete", filter: (u) => u.trainingCompletePercentage === 100 }
]

const permissions = [
  { name: "Training", property: 'training'  },
  { name: "User Management", property: 'userManagement' },
  { name: "Threat Team", property: 'threatTeam' },
  { name: "Self Harm", property: 'viewSelfHarm' },
  { name: "View All Behavioral Assessments", property: 'viewAllThreats' },
  { name: "Emergency Plan", property: 'emergencyPlan' },
  { name: "Vulnerability Assessment", property: 'riskSurvey' }
]

export default function Users() {
    const context = useContext(AppContext)
    const { selectedInstitutionIdParam } = useParams()

    const [loading, setLoading] = useState(true)
    const [processing, setProcessing] = useState()
    const [allInstitutions, setAllInstitutions] = useState([])

    const [selectedInstitutionId, setSelectedInstitutionId] = useState()
    const [selectedBuildingIds, setSelectedBuildingIds] = useState([])

    const [selectedBuildingId, setSelectedBuildingId] = useState()

    const [allViewBuildings, setAllViewBuildings] = useState([])
    const [viewBuilding, setViewBuilding] = useState([])

    const [editedUser, setEditedUser] = useState()
    const [existingUser, setExistingUser] = useState(false)
    
    const [completableSystems, setCompletableSystems] = useState([])
    const [filterSystem, setFilterSystem] = useState()
    const [filterStatus, setFilterStatus] = useState()
    const [filterPermission, setFilterPermission] = useState()
   

    const [searchWord, setSearchWord] = useState()
    const [filterSearch, setFilterSearch] = useState()
    
    const [showAddUser, setShowAddUser] = useState(false)
    const [showAddBulkUser, setShowAddBulkUser] = useState(false)

    let loadViewBuilding = async () => {
      if (selectedBuildingId) {
        let updateViewBuilding = allViewBuildings.find( b => b.institutionBuildingId === selectedBuildingId)
        let users = await new userService().getUsersByBuildingId(selectedBuildingId) 

        updateViewBuilding.users = users.filter( u => u.institutionBuildingId === updateViewBuilding.institutionBuildingId)
        updateViewBuilding.users.forEach( u => {
          u.institutionBuildingId = updateViewBuilding.institutionBuildingId
          u.institutionId = updateViewBuilding.institutionId
    
          u.trainingCompletePercentage =  Math.floor((u.completedSystems.length/u.completableSystems.length) * 100)
        })
        updateViewBuilding.allUsers = updateViewBuilding.users
        //get aggregate training
        updateViewBuilding.aggregateTrainingPercent = Math.floor((updateViewBuilding.users.filter( u => u.training && !u.notIncludedInInstitutionTrainingStatistics && u.trainingCompletePercentage === 100).length / updateViewBuilding.allUsers.filter( au => au.training && !au.notIncludedInInstitutionTrainingStatistics).length) * 100)

        setViewBuilding({...updateViewBuilding})
      }
    }

    //on load
    useEffect( async () => {
      window.scroll(0,0)
      let systems = context.systems
      let completableSystems = systems.filter( s => s.isCompletable)
      //unique
      completableSystems = [...new Map(completableSystems.map(item =>[item.system, item])).values()];
      setCompletableSystems(completableSystems)

      try {
        let institutions = await new institutionService().getInstitutions()
        institutions.forEach( i => {
          i.buildings.forEach( b => {
            b.institutionName = i.name
          })
        })
        setAllInstitutions(institutions)
       

        if (selectedInstitutionIdParam)
          setSelectedInstitutionId(+selectedInstitutionIdParam)
        else {
          if (institutions.length === 1)
            setSelectedInstitutionId(institutions[0].institutionId)
        }
      }
      catch(err){
          console.log(err)
          context.toastError('Loading')
          Sentry.captureException(err);
      }
    },[])

    //if one building at the institution, show
    useEffect(() => {
      setViewBuilding()
      setSelectedBuildingId()
      if (selectedInstitutionId) {
        let institution = allInstitutions.find( i => i.institutionId === selectedInstitutionId)
        let allViewBuildings = institution.buildings
        setAllViewBuildings(allViewBuildings)
        if (allViewBuildings && allViewBuildings.length === 1) 
          setSelectedBuildingId(allViewBuildings[0].institutionBuildingId)
      }
    },[selectedInstitutionId])

    //on selecting a building
    useEffect(() => {
      setFilterSystem()
      setFilterPermission()
      setFilterSearch()
      setFilterStatus()
      loadViewBuilding()
      
    },[selectedBuildingId])

  //select building show the buildings
  useEffect(() => {
    if(selectedBuildingId) {
      setViewBuilding(allViewBuildings.find(vb => vb.institutionBuildingId === selectedBuildingId))
      setSelectedBuildingIds([selectedBuildingId])
    }
  },[selectedBuildingId])

  //Search
  useEffect(() => {
    let filteredUsers = _.cloneDeep(viewBuilding.allUsers)
    if (filterSystem){
      //temporarily remove all other completed training
      filteredUsers.forEach( u => {
        u.completedSystems = u.completedSystems.filter(cs => cs.system === filterSystem)
        u.completableSystems = u.completableSystems.filter(cs => cs.system === filterSystem)
        u.trainingCompletePercentage =  Math.floor((u.completedSystems.length/u.completableSystems.length) * 100)
      })
    }
    if (filterStatus)
      filteredUsers = filteredUsers.filter( u => progressOptions.find( po => po.status === filterStatus).filter(u) )
    if (filterSearch)
      filteredUsers = filteredUsers.filter(  u => u.email.toLowerCase().includes(filterSearch.toLowerCase()) || `${u.firstName} ${u.lastName}`.toLowerCase().includes(filterSearch.toLowerCase()))
    if (filterPermission)
      filteredUsers = filteredUsers.filter( u => !!u[filterPermission] )
    viewBuilding.users = filteredUsers
    setViewBuilding({...viewBuilding})
   },[filterStatus, filterSystem, filterPermission, filterSearch])

   const debouncedSearchUpdated = useDebouncedCallback(
    () => {
       setFilterSearch(searchWord)
    },
    300
  );
  useEffect( () => {
      debouncedSearchUpdated()
  },[searchWord])

   //re-send invites to one individual
   let inviteUser = async (email) => {
    try {
      await new userService().reInvite(email)
    }
    catch (err){
      console.log(err)
      context.toastError(`Invite failed ${email}`)
      Sentry.captureException(err);
    }
  }
  
   let handleInviteAllIncomplete = async () => {
    setProcessing('inviteAll')
    await Promise.all(viewBuilding.users.filter(u => u.trainingCompletePercentage < 100).map( (user) => { return inviteUser(user.email) }))
    setProcessing(null)
    context.toastSuccess('Re-Invited Incomplete')
   }

   let handleEditedUserToggleBuildingId = (buildingId, checked) => {
    let updateSelectedBuildingIds = editedUser.buildings.map(b => b.institutionBuildingId)
    if (checked)
        updateSelectedBuildingIds.push(buildingId)
    else
       updateSelectedBuildingIds.splice(updateSelectedBuildingIds.indexOf(buildingId),1)
    editedUser.buildings = updateSelectedBuildingIds.map( b => { return { institutionBuildingId: b } })
    setEditedUser({...editedUser})
}
  
    let updateUser = async (e, userToUpdate) => {
      e.preventDefault()
      try {
        let updateUsers = viewBuilding.users
        updateUsers[updateUsers.findIndex( u => u.userId === userToUpdate.userId)] = userToUpdate  
        let updateResult = await new userService().update(userToUpdate)
        if (updateResult.existingUser) {
          setExistingUser(true)
          return
        }
        
        setEditedUser(null)
        loadViewBuilding()
        context.toastSuccess('User edited')
      }
      catch (err){
        console.log(err)
        context.toastError('User not edited')
        Sentry.captureException(err);
      }
    }
  

    const requiredCheckbox = editedUser && !editedUser.training && !editedUser.userManagement && !editedUser.threatTeam && !editedUser.viewSelfHarm && !editedUser.viewAllThreats && !editedUser.reviewThreats && !editedUser.emergencyPlan && !editedUser.riskSurvey
    const requiredSelectedBuildingIds= !selectedBuildingIds.length


  return (    
      <div id='users'>
        { !!editedUser ? 
        <Dialog fullWidth={true} maxWidth={'md'} open={!!editedUser} onClose={ e => setEditedUser(null)} aria-labelledby="simple-dialog-title">
            <DialogTitle id="dialog-title">Edit User</DialogTitle>
            <div className="editedUser-dialog">
              <form onSubmit={ e => { updateUser(e, editedUser) }}>
                <div className="flex-row section" >
                    <div className="flex-col">
                      <TextField className="editedUserEmail" error={existingUser} helperText={existingUser ? 'This email is used by another user.  Please add the user to your building.' : ''} label="Email" type="email" maxLength="320" value={editedUser.email || ''} required onChange={ e  => { setExistingUser(false); editedUser.email = e.target.value; setEditedUser({...editedUser})}}/>
                    </div>
                </div>
                <div className="flex-row section" >
                    <div className="flex-col">
                      <TextField className="editedUserFirstName" label="First Name" type="text" maxLength="45" value={editedUser.firstName || ''} onChange={(e) => { editedUser.firstName = e.target.value; setEditedUser({...editedUser})}}/>
                    </div>
                    <div className="flex-col">
                      <TextField className="editedUserLastName" label="Last Name" type="text" maxLength="45" value={editedUser.lastName || ''} onChange={(e) => { editedUser.lastName = e.target.value; setEditedUser({...editedUser})}}/>
                    </div>
                </div>

                { allViewBuildings.length > 1 ?
                      <div>
                        <h2>Buildings</h2>
                        <div className="flex-row section" >
                            <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                { allViewBuildings.map( (building, index) => {
                                    return(
                                    <FormControlLabel key={index}
                                        control={<Checkbox required={requiredSelectedBuildingIds} />}
                                        label={building.buildingName}
                                        labelPlacement="end"
                                        checked={!!editedUser.buildings.find(b => b.institutionBuildingId === building.institutionBuildingId)}
                                        onChange={ (e)=> handleEditedUserToggleBuildingId(building.institutionBuildingId, e.target.checked) }
                                    />
                                    )
                                })}
                            </FormGroup>
                            </FormControl>
                        </div>
                      </div>

                  : null }


                <div className="flex-row section">
                  <div className="flex-col">
                  <FormControl 
                      component="fieldset"
                  >
                      <h2><span>Access</span><span><HelpTooltip title={<div><div>Training - Require User to take training</div><div>User Management - Access to Add, Edit and View Users</div><div>Threat Team - Members handling Behavior Assessments</div><div>View All Behavioral Assessments - Access to edit all Behavior Assessments</div><div>Emergency Management - Access to edit and view Plans</div><div>Vulnerability Assessment - Access to edit and view Vulnerability Assessments</div></div>}></HelpTooltip></span></h2>

                      <FormGroup aria-label="position">
                          <FormControlLabel
                              control={<Checkbox required={requiredCheckbox} />}
                              label="Training"
                              labelPlacement="end"
                              checked={!!editedUser.training}
                              onChange={ (e) => { editedUser.training = e.target.checked ? 1 : 0 ; setEditedUser({...editedUser}) }}
                          />
                          <FormControlLabel
                              control={<Checkbox required={requiredCheckbox} />}
                              label="User Management"
                              labelPlacement="end"
                              checked={!!editedUser.userManagement}
                              onChange={ (e) => { editedUser.userManagement = e.target.checked ? 1 : 0 ; setEditedUser({...editedUser}) }}
                          />
                           <FormGroup aria-label="position" row>
                              <FormControlLabel
                                  control={<Checkbox required={requiredCheckbox} />}
                                  label="View All Behavioral Assessments"
                                  labelPlacement="end"
                                  checked={!!editedUser.viewAllThreats}
                                  onChange={ (e) => { editedUser.viewAllThreats = e.target.checked ? 1 : 0 ; setEditedUser({...editedUser}) }}
                              />
                              <FormControlLabel
                                  control={<Checkbox required={requiredCheckbox} />}
                                  disabled={!!editedUser.viewAllThreats}
                                  label="Threat Team"
                                  labelPlacement="end"
                                  checked={!!editedUser.threatTeam}
                                  onChange={ (e) => { editedUser.threatTeam = e.target.checked ? 1 : 0 ; setEditedUser({...editedUser}) }}
                              />
                              <FormControlLabel
                                  control={<Checkbox required={requiredCheckbox} />}
                                  disabled={!!editedUser.viewAllThreats}
                                  label="View Self Harm"
                                  labelPlacement="end"
                                  checked={!!editedUser.viewSelfHarm}
                                  onChange={ (e) => { editedUser.viewSelfHarm = e.target.checked ? 1 : 0 ; setEditedUser({...editedUser}) }}
                              />

                          </FormGroup>
                    
                          <FormControlLabel
                              control={<Checkbox required={requiredCheckbox} />}
                              label=" Emergency Plan"
                              labelPlacement="end"
                              checked={!!editedUser.emergencyPlan}
                              onChange={ (e) => { editedUser.emergencyPlan = e.target.checked ? 1 : 0 ; setEditedUser({...editedUser}) }}
                          />
                          <FormControlLabel
                              control={<Checkbox required={requiredCheckbox} />}
                              label=" Vulnerability Assessment"
                              labelPlacement="end"
                              checked={!!editedUser.riskSurvey}
                              onChange={ (e) => { editedUser.riskSurvey = e.target.checked ? 1 : 0 ; setEditedUser({...editedUser}) }}
                          />
                      </FormGroup>
                  </FormControl>
                  </div>
                  <div className="section">
                      <h2><span>Additional Permissions</span><span><HelpTooltip title={<div><div>Review HPTM Behaviors - Review All HPTM Threats created at this building</div></div>}></HelpTooltip></span></h2>

                      <FormControlLabel
                              control={<Checkbox required={requiredCheckbox} />}
                              label="Review HPTM Behaviors"
                              labelPlacement="end"
                              checked={!!editedUser.reviewThreats}
                              onChange={ (e) => { editedUser.reviewThreats = e.target.checked ? 1 : 0 ; setEditedUser({...editedUser}) }}
                          />
                  </div>
                </div>

                <div className="flex-row section">
                  <Button type="submit" variant="outlined"><Icon className="fa fa-save" />Save</Button>
                </div>
             </form> 
            </div>
        </Dialog> 
        : null }

        <div className="flex-row top-bar">
          { !showAddUser && !showAddBulkUser ?
            <div className="flex-col account-selection">
              { allInstitutions.length > 1 || (selectedInstitutionId && allViewBuildings?.length > 1) ? 
                  <div className="flex-row ">
                    { allInstitutions.length > 1 ? 
                    <div className="flex-col selection">
                        <TextField fullWidth label="Account" select value={selectedInstitutionId || '' } required onChange={(e) => { setSelectedInstitutionId(e.target.value);}}>           
                          { allInstitutions.map( (institution, index) => {
                              return(
                                <MenuItem key={index} value={institution.institutionId}>
                                  {institution.name}
                                </MenuItem>
                              )
                            }) 
                          }
                        </TextField>
                      </div>
                      : null }

                    { selectedInstitutionId && allViewBuildings?.length > 1 ? 
                      <div className="flex-col selection">
                        <TextField fullWidth label="Building" select value={selectedBuildingId || '' } required onChange={(e) => { setSelectedBuildingId(e.target.value); }}>           
                          { allViewBuildings.map( (building, index) => {
                              return(
                                <MenuItem key={index} value={building.institutionBuildingId}>
                                  {building.buildingName}
                                </MenuItem>
                              )
                            }) 
                          }
                        </TextField>
                      </div>
                    : null }
                  </div>
              : null }
            </div>
          : null }

          <div className="flex-col">
            <div className="flex-row actions">
              { selectedBuildingId ?
              <div className="flex-col" >
                <Button variant="outlined" onClick={ e => { setShowAddBulkUser(false); setShowAddUser(!showAddUser); }}><Icon className="fa fa-user" />Add User</Button>
              </div>
              : null }
              { selectedBuildingId ?
              <div className="flex-col" >
                <Button variant="outlined" onClick={ e => { setShowAddBulkUser(!showAddBulkUser); setShowAddUser(false); }}><Icon className="fa fa-users"/>Add Multiple Users</Button>
              </div>
              : null }

              <div className='flex-col top-menu'>
                <DotMenu> 
                  <DotMenuItem disabled={!!processing} onClick={handleInviteAllIncomplete} fontAwesomeIcon="fa fa-paper-plane" title="Re-Invite Incomplete" help="Invite Only Incomplete Users"></DotMenuItem>
                  <Link to="/building/add"><DotMenuItem fontAwesomeIcon="fa fa-building" title="Add Building" ></DotMenuItem></Link>
                </DotMenu> 
              </div>
            </div>
          </div>
        </div>

        {  selectedInstitutionId && selectedBuildingId && allViewBuildings.length ?
        <Paper className="paper">
          { showAddUser ?
            <div className="section addUserSection">
              <AddUser institution={allInstitutions.find( i => i.institutionId === selectedInstitutionId)} buildings={allViewBuildings} selectedBuildingId={selectedBuildingId} onSave={ e => { setShowAddUser(false); loadViewBuilding(); } } onClose={ e => setShowAddUser(false) } ></AddUser>
            </div>
          : null }
          { showAddBulkUser ?
            <div className="section addUserSection">
              <BulkAddUsers institution={allInstitutions.find( i => i.institutionId === selectedInstitutionId)} buildings={allViewBuildings} selectedBuildingId={selectedBuildingId} onSave={ e => { setShowAddBulkUser(false); loadViewBuilding(); } } onClose={ e => setShowAddBulkUser(false) } ></BulkAddUsers>
            </div>
          : null }

          { !showAddBulkUser && !showAddUser ?
            <div className="table">
              <Paper className="flex-row search-bar section">
                <div className="flex-row search-fields">
                  <div className="flex-col search-field search-field-small">
                    <TextField select label="Training" value={filterSystem ? completableSystems.find( po => po.system === filterSystem).system : ''} onChange={ e => setFilterSystem(e.target.value) }>
                      <MenuItem value="">All</MenuItem> 
                      { completableSystems.map( (completableSystem, index) => {
                        return(
                          <MenuItem key={index} value={completableSystem.system}>{completableSystem.name}</MenuItem> 
                          )
                        })
                      }                                
                    </TextField>
                  </div>
                  <div className="flex-col search-field search-field-small">
                    <TextField select label="Training Status" value={filterStatus ? progressOptions.find( po => po.status === filterStatus).status : ''} onChange={ e => setFilterStatus(e.target.value) }>
                      <MenuItem value="">All</MenuItem> 
                      { progressOptions.map( (progressOption, index) => {
                        return(
                          <MenuItem key={index} value={progressOption.status}>{progressOption.status}</MenuItem> 
                          )
                        })
                      }                                
                    </TextField>
                  </div>
                  <div className="flex-col search-field search-field-small">
                    <TextField select label="Permissions" value={filterPermission ? permissions.find( po => po.property === filterPermission).property : ''} onChange={ e => setFilterPermission(e.target.value) }>
                      <MenuItem value="">All</MenuItem> 
                      { permissions.map( (permission, index) => {
                        return(
                          <MenuItem key={index} value={permission.property}>{permission.name}</MenuItem> 
                          )
                        })
                      }                                
                    </TextField>
                  </div>

                  <div className="flex-col">
                    <TextField className="search-field" maxLength="60" type="text" label="Search" onChange={ e => setSearchWord(e.target.value)} />
                  </div>
                </div>
              </Paper>
              
              { viewBuilding ?
                <Paper className="building">
                  <h2 className="buildingName">{allInstitutions.length > 1 ? `${viewBuilding.institutionName} - ` : ''}{viewBuilding.buildingName}</h2> 
                  <Link className="buildingEditLink" to={`/building/edit/${viewBuilding.institutionBuildingId}`}><Icon className="fa fa-edit" /></Link>
                  <div className="buildingInfo">
                    {viewBuilding.buildingAddress ? <span>{viewBuilding.buildingAddress}</span> : null }
                    {viewBuilding.buildingPhone ? <span> {viewBuilding.buildingPhone}</span> : null }
                  </div>
                  { viewBuilding.users?.length ?
                    <UserList building={viewBuilding} aggregateTrainingPercent={viewBuilding.aggregateTrainingPercent} onUpdateBuildingUsers={ (users) => { viewBuilding.users = users; setViewBuilding(viewBuilding)} } onSetEditedUser={ (user) => setEditedUser(user) }></UserList> 
                  : 
                    <div className="section">No Users have been found</div> 
                  }
                </Paper>
              : null }
            </div>
          : null }
        </Paper>
        : null }

      </div>
    )
}